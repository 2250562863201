import * as THREE from "three";
import { GUI } from 'three/examples/jsm/libs/lil-gui.module.min.js';
import { scene } from './KukanManagedClass';

const ambientLight = new THREE.AmbientLight();
const hemisphereLight = new THREE.HemisphereLight();
const spotLight_1 = new THREE.SpotLight();
const spotLight_2 = new THREE.SpotLight();

export let lightHelper_1: THREE.SpotLightHelper;
export let lightHelper_2: THREE.SpotLightHelper;

export function getKukanLightConf () {
 
  const lightConf = {
    // AmbientLight
    ambientLight: {
      ambientLight_color: ambientLight.color,
      ambientLight_intensity: ambientLight.intensity,
    },
    // HemisphereLight
    hemisphereLight: {
      hemisphereLight_color: hemisphereLight.color,
      hemisphereLight_ground_color: hemisphereLight.groundColor,
      hemisphereLight_intensity: hemisphereLight.intensity,
    },
    // SpotLight 1
    spotLight_1: {
      spotLight_1_color: spotLight_1.color,
      spotLight_1_intensity: spotLight_1.intensity,
      spotLight_1_distance: spotLight_1.distance,
      spotLight_1_angle: spotLight_1.angle,
      spotLight_1_penumbra: spotLight_1.penumbra,
      spotLight_1_decay: spotLight_1.decay,
      spotLight_1_shadow_focus: spotLight_1.shadow.focus,
      spotLight_1_position_x: spotLight_1.position.x,
      spotLight_1_position_y: spotLight_1.position.y,
      spotLight_1_position_z: spotLight_1.position.z,
    },
    // SpotLight 2,
    spotLight_2: {
      spotLight_2_color: spotLight_2.color,
      spotLight_2_intensity: spotLight_2.intensity,
      spotLight_2_distance: spotLight_2.distance,
      spotLight_2_angle: spotLight_2.angle,
      spotLight_2_penumbra: spotLight_2.penumbra,
      spotLight_2_decay: spotLight_2.decay,
      spotLight_2_shadow_focus: spotLight_2.shadow.focus,
      spotLight_2_position_x: spotLight_2.position.x,
      spotLight_2_position_y: spotLight_2.position.y,
      spotLight_2_position_z: spotLight_2.position.z,
    }
  }
  return lightConf;
}

export function initGUI(props_data) {
  const LIGHT_DATA: lightConfType = props_data.kukan_details.kukan[0].light_conf;
  
  const gui = new GUI();
  gui.close();
  const MAX = 100;
  const MIN = -100;
  
  // const areaLight = new THREE.RectAreaLight(0xFFFFFF, 0, 10, 10);
  // areaLight.rotation.y = 90
  // scene.add(areaLight);

  // const paramsArea = {
  //   color: areaLight.color.getHex(),
  //   intensity: areaLight.intensity,
  //   width: areaLight.width,
  //   height: areaLight.height,
  //   area_pos_X: areaLight.position.x,
  //   area_pos_Y: areaLight.position.y,
  //   area_pos_Z: areaLight.position.z,
  //   area_rot_X: areaLight.rotation.x,
  //   area_rot_Y: areaLight.rotation.y,
  //   area_rot_Z: areaLight.rotation.z,
  // };

  // const areaLightFolder = gui.addFolder("Area Light");
  // areaLightFolder.addColor(paramsArea, 'color')
  //   .onChange((val) => { areaLight.color.setHex(val) });

  // areaLightFolder.add(paramsArea, 'intensity', 0, 30)
  //   .onChange((val) => { areaLight.intensity = val });
  
  // areaLightFolder.add(paramsArea, 'width', 0, 100)
  //   .onChange((val) => { areaLight.width = val });

  // areaLightFolder.add(paramsArea, 'height', 0, 100)
  //   .onChange((val) => { areaLight.height = val });

  // areaLightFolder.add(paramsArea, 'area_pos_X', MIN, MAX)
  //   .onChange((val) => { areaLight.position.x = val });

  // areaLightFolder.add(paramsArea, 'area_pos_Y', MIN, MAX)
  // .onChange((val) => { areaLight.position.y = val });

  // areaLightFolder.add(paramsArea, 'area_pos_Z', MIN, MAX)
  //   .onChange((val) => { areaLight.position.z = val });

  // areaLightFolder.add(paramsArea, 'area_rot_X', 0, 1)
  //   .onChange((val) => { areaLight.rotateX(val) });

  // areaLightFolder.add(paramsArea, 'area_rot_Y', 0, 1)
  // .onChange((val) => { areaLight.rotateY(val) });

  // areaLightFolder.add(paramsArea, 'area_rot_Z', -180, 180)
  //   .onChange((val) => { areaLight.rotateZ(val) });

  // areaLightFolder.open();
  
  /////////////////////////////////////////////
  // AmbientLight
  /////////////////////////////////////////////
  ambientLight.color.setHex(LIGHT_DATA.ambientLight.ambientLight_color);
  ambientLight.intensity = LIGHT_DATA.ambientLight.ambientLight_intensity;
	scene.add(ambientLight);

  const paramsAmbi = {
    color_Ambi: ambientLight.color.getHex(),
    intensity_Ambi: ambientLight.intensity,
  };
  
  const ambiLightFolder = gui.addFolder("Ambi Light");
  ambiLightFolder.addColor(paramsAmbi, 'color_Ambi')
    .onChange((val) => { ambientLight.color.setHex(val) });

  ambiLightFolder.add(paramsAmbi, 'intensity_Ambi', 0, 10)
    .onChange((val) => { ambientLight.intensity = val });

  ambiLightFolder.open();

  /////////////////////////////////////////////
  // HemisphereLight
  /////////////////////////////////////////////
  hemisphereLight.color.setHex(LIGHT_DATA.hemisphereLight.hemisphereLight_color);
  hemisphereLight.groundColor.setHex(LIGHT_DATA.hemisphereLight.hemisphereLight_ground_color);
  hemisphereLight.intensity = LIGHT_DATA.hemisphereLight.hemisphereLight_intensity;
	scene.add(hemisphereLight);

  const paramsHemi = {
    color_Sky: hemisphereLight.color.getHex(),
    color_Ground: hemisphereLight.groundColor.getHex(),
    intensity_Ambi: hemisphereLight.intensity,
  };

  const hemiLightFolder = gui.addFolder("Hemi Light");
  hemiLightFolder.addColor(paramsHemi, 'color_Sky')
    .onChange((val) => { hemisphereLight.color.setHex(val) });

  hemiLightFolder.addColor(paramsHemi, 'color_Ground')
  .onChange((val) => { hemisphereLight.groundColor.setHex(val) });

  hemiLightFolder.add(paramsHemi, 'intensity_Ambi', 0, 10)
    .onChange((val) => { hemisphereLight.intensity = val });

  hemiLightFolder.open();

  /////////////////////////////////////////////
  // SpotLight 1
  /////////////////////////////////////////////
  spotLight_1.color.setHex(LIGHT_DATA.spotLight_1.spotLight_1_color);
  spotLight_1.intensity = LIGHT_DATA.spotLight_1.spotLight_1_intensity; // 強さ
  spotLight_1.distance = LIGHT_DATA.spotLight_1.spotLight_1_distance;
  spotLight_1.angle = LIGHT_DATA.spotLight_1.spotLight_1_angle; // 照射角
  spotLight_1.penumbra = LIGHT_DATA.spotLight_1.spotLight_1_penumbra; // ボケ
  spotLight_1.decay = LIGHT_DATA.spotLight_1.spotLight_1_decay; // 減衰率
  spotLight_1.position.set(LIGHT_DATA.spotLight_1.spotLight_1_position_x, LIGHT_DATA.spotLight_1.spotLight_1_position_y, LIGHT_DATA.spotLight_1.spotLight_1_position_z);

  spotLight_1.castShadow = true;
  spotLight_1.shadow.mapSize.width = 1024;
  spotLight_1.shadow.mapSize.height = 1024;
  // spotLight_1.shadow.camera.near = 10;
  // spotLight_1.shadow.camera.far = 200;
  // spotLight_1.shadow.focus = 1;
  scene.add(spotLight_1);

  // // Target
  // const targetObject_1 = new THREE.Object3D();
	// targetObject_1.position.set(0, -30, 30);
	// spotLight_1.target = targetObject_1;
	// scene.add(targetObject_1);

  // SpotLightHelper
  lightHelper_1 = new THREE.SpotLightHelper(spotLight_1);
  scene.add(lightHelper_1);

  const paramsSpot_1 = {
    color: spotLight_1.color.getHex(),
    intensity: spotLight_1.intensity,
    distance: spotLight_1.distance,
    angle: spotLight_1.angle,
    penumbra: spotLight_1.penumbra,
    decay: spotLight_1.decay,
    focus: spotLight_1.shadow.focus,
    shadows: true,
    spotlight_pos_X: spotLight_1.position.x,
    spotlight_pos_Y: spotLight_1.position.y,
    spotlight_pos_Z: spotLight_1.position.z,
    // target_pos_X: targetObject_1.position.x,
    // target_pos_Y: targetObject_1.position.y,
    // target_pos_Z: targetObject_1.position.z,
  };

  const SpotLightFolder_1 = gui.addFolder("Spot Light 1");
  SpotLightFolder_1.addColor(paramsSpot_1, 'color')
    .onChange((val) => { spotLight_1.color.setHex(val) });

  SpotLightFolder_1.add(paramsSpot_1, 'intensity', 0, 500)
    .onChange((val) => { spotLight_1.intensity = val });

  SpotLightFolder_1.add(paramsSpot_1, 'distance', 50, 200)
    .onChange((val) => { spotLight_1.distance = val });

  SpotLightFolder_1.add(paramsSpot_1, 'angle', 0, Math.PI / 2)
    .onChange((val) => { spotLight_1.angle = val });

  SpotLightFolder_1.add(paramsSpot_1, 'penumbra', 0, 1)
    .onChange((val) => { spotLight_1.penumbra = val });

  SpotLightFolder_1.add(paramsSpot_1, 'decay', 1, 2)
    .onChange((val) => { spotLight_1.decay = val });

  SpotLightFolder_1.add(paramsSpot_1, 'focus', 0, 1)
    .onChange((val) => { spotLight_1.shadow.focus = val });

  SpotLightFolder_1.add(paramsSpot_1, 'spotlight_pos_X', MIN, MAX)
    .onChange((val) => { spotLight_1.position.x = val });

  SpotLightFolder_1.add(paramsSpot_1, 'spotlight_pos_Y', MIN, MAX)
    .onChange((val) => { spotLight_1.position.y = val });

  SpotLightFolder_1.add(paramsSpot_1, 'spotlight_pos_Z', MIN, MAX)
    .onChange((val) => { spotLight_1.position.z = val });

  // SpotLightFolder_1.add(paramsSpot_1, 'target_pos_X', MIN, MAX)
  //   .onChange((val) => { targetObject_1.position.x = val });

  // SpotLightFolder_1.add(paramsSpot_1, 'target_pos_Y', MIN, MAX)
  //   .onChange((val) => { targetObject_1.position.y = val });

  // SpotLightFolder_1.add(paramsSpot_1, 'target_pos_Z', MIN, MAX)
  //   .onChange((val) => { targetObject_1.position.z = val });

  SpotLightFolder_1.open();

  ///////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////


  
  /////////////////////////////////////////////
  // SpotLight 2
  /////////////////////////////////////////////
  spotLight_2.color.setHex(LIGHT_DATA.spotLight_2.spotLight_2_color);
  spotLight_2.intensity = LIGHT_DATA.spotLight_2.spotLight_2_intensity; // 強さ
  spotLight_2.distance = LIGHT_DATA.spotLight_2.spotLight_2_distance;
  spotLight_2.angle = LIGHT_DATA.spotLight_2.spotLight_2_angle; // 照射角
  spotLight_2.penumbra = LIGHT_DATA.spotLight_2.spotLight_2_penumbra; // ボケ
  spotLight_2.decay = LIGHT_DATA.spotLight_2.spotLight_2_decay; // 減衰率
  spotLight_2.position.set(LIGHT_DATA.spotLight_2.spotLight_2_position_x, LIGHT_DATA.spotLight_2.spotLight_2_position_y, LIGHT_DATA.spotLight_2.spotLight_2_position_z);

  spotLight_2.castShadow = true;
  spotLight_2.shadow.mapSize.width = 1024;
  spotLight_2.shadow.mapSize.height = 1024;
  // spotLight_2.shadow.camera.near = 10;
  // spotLight_2.shadow.camera.far = 200;
  // spotLight_2.shadow.focus = 1;
  scene.add(spotLight_2);

  // // Target
  // const targetObject_2 = new THREE.Object3D();
	// targetObject_2.position.set(40, -30, -80);
	// spotLight_2.target = targetObject_2;
	// scene.add(targetObject_2);

  // SpotLightHelper
  lightHelper_2 = new THREE.SpotLightHelper(spotLight_2);
  scene.add(lightHelper_2);

  const paramsSpot_2 = {
    color: spotLight_2.color.getHex(),
    intensity: spotLight_2.intensity,
    distance: spotLight_2.distance,
    angle: spotLight_2.angle,
    penumbra: spotLight_2.penumbra,
    decay: spotLight_2.decay,
    focus: spotLight_2.shadow.focus,
    shadows: true,
    spotlight_pos_X: spotLight_2.position.x,
    spotlight_pos_Y: spotLight_2.position.y,
    spotlight_pos_Z: spotLight_2.position.z,
    // target_pos_X: targetObject_2.position.x,
    // target_pos_Y: targetObject_2.position.y,
    // target_pos_Z: targetObject_2.position.z,
  };

  const SpotLightFolder_2 = gui.addFolder("Spot Light 2");
  SpotLightFolder_2.addColor(paramsSpot_2, 'color')
    .onChange((val) => { spotLight_2.color.setHex(val) });

  SpotLightFolder_2.add(paramsSpot_2, 'intensity', 0, 500)
    .onChange((val) => { spotLight_2.intensity = val });

  SpotLightFolder_2.add(paramsSpot_2, 'distance', 50, 200)
    .onChange((val) => { spotLight_2.distance = val });

  SpotLightFolder_2.add(paramsSpot_2, 'angle', 0, Math.PI / 2)
    .onChange((val) => { spotLight_2.angle = val });

  SpotLightFolder_2.add(paramsSpot_2, 'penumbra', 0, 1)
    .onChange((val) => { spotLight_2.penumbra = val });

  SpotLightFolder_2.add(paramsSpot_2, 'decay', 1, 2)
    .onChange((val) => { spotLight_2.decay = val });

  SpotLightFolder_2.add(paramsSpot_2, 'focus', 0, 1)
    .onChange((val) => { spotLight_2.shadow.focus = val });

  SpotLightFolder_2.add(paramsSpot_2, 'spotlight_pos_X', MIN, MAX)
    .onChange((val) => { spotLight_2.position.x = val });

  SpotLightFolder_2.add(paramsSpot_2, 'spotlight_pos_Y', MIN, MAX)
    .onChange((val) => { spotLight_2.position.y = val });

  SpotLightFolder_2.add(paramsSpot_2, 'spotlight_pos_Z', MIN, MAX)
    .onChange((val) => { spotLight_2.position.z = val });

  // SpotLightFolder_2.add(paramsSpot_2, 'target_pos_X', MIN, MAX)
  //   .onChange((val) => { targetObject_2.position.x = val });

  // SpotLightFolder_2.add(paramsSpot_2, 'target_pos_Y', MIN, MAX)
  //   .onChange((val) => { targetObject_2.position.y = val });

  // SpotLightFolder_2.add(paramsSpot_2, 'target_pos_Z', MIN, MAX)
  //   .onChange((val) => { targetObject_2.position.z = val });

  SpotLightFolder_2.open();

}

export function initNewGUI() {
  
  const gui = new GUI();
  gui.close();
  const MAX = 100;
  const MIN = -100;
  
  /////////////////////////////////////////////
  // AmbientLight
  /////////////////////////////////////////////
  ambientLight.color.set(0xffffff);
  ambientLight.intensity = 0.5;
	scene.add(ambientLight);

  const paramsAmbi = {
    color_Ambi: ambientLight.color.getHex(),
    intensity_Ambi: ambientLight.intensity,
  };
  
  const ambiLightFolder = gui.addFolder("Ambi Light");
  ambiLightFolder.addColor(paramsAmbi, 'color_Ambi')
    .onChange((val) => { ambientLight.color.setHex(val) });

  ambiLightFolder.add(paramsAmbi, 'intensity_Ambi', 0, 10)
    .onChange((val) => { ambientLight.intensity = val });

  ambiLightFolder.open();

  /////////////////////////////////////////////
  // HemisphereLight
  /////////////////////////////////////////////
  hemisphereLight.color.set(0xffffff);
  hemisphereLight.groundColor.set(0x444444);
  hemisphereLight.intensity = 1.0;
	scene.add(hemisphereLight);

  const paramsHemi = {
    color_Sky: hemisphereLight.color.getHex(),
    color_Ground: hemisphereLight.groundColor.getHex(),
    intensity_Ambi: hemisphereLight.intensity,
  };

  const hemiLightFolder = gui.addFolder("Hemi Light");
  hemiLightFolder.addColor(paramsHemi, 'color_Sky')
    .onChange((val) => { hemisphereLight.color.setHex(val) });

  hemiLightFolder.addColor(paramsHemi, 'color_Ground')
  .onChange((val) => { hemisphereLight.groundColor.setHex(val) });

  hemiLightFolder.add(paramsHemi, 'intensity_Ambi', 0, 10)
    .onChange((val) => { hemisphereLight.intensity = val });

  hemiLightFolder.open();

  /////////////////////////////////////////////
  // SpotLight 1
  /////////////////////////////////////////////
  spotLight_1.color.set(0xffffff);
  spotLight_1.intensity = 350; // 強さ
  spotLight_1.distance = 100;
  spotLight_1.angle = Math.PI / 4; // 照射角
  spotLight_1.penumbra = 1; // ボケ
  spotLight_1.decay = 1.3; // 減衰率
  spotLight_1.position.set(0, 26, 26);

  spotLight_1.castShadow = true;
  spotLight_1.shadow.mapSize.width = 1024;
  spotLight_1.shadow.mapSize.height = 1024;
  scene.add(spotLight_1);

  // SpotLightHelper
  lightHelper_1 = new THREE.SpotLightHelper(spotLight_1);
  scene.add(lightHelper_1);

  const paramsSpot_1 = {
    color: spotLight_1.color.getHex(),
    intensity: spotLight_1.intensity,
    distance: spotLight_1.distance,
    angle: spotLight_1.angle,
    penumbra: spotLight_1.penumbra,
    decay: spotLight_1.decay,
    focus: spotLight_1.shadow.focus,
    shadows: true,
    spotlight_pos_X: spotLight_1.position.x,
    spotlight_pos_Y: spotLight_1.position.y,
    spotlight_pos_Z: spotLight_1.position.z,
  };

  const SpotLightFolder_1 = gui.addFolder("Spot Light 1");
  SpotLightFolder_1.addColor(paramsSpot_1, 'color')
    .onChange((val) => { spotLight_1.color.setHex(val) });

  SpotLightFolder_1.add(paramsSpot_1, 'intensity', 0, 500)
    .onChange((val) => { spotLight_1.intensity = val });

  SpotLightFolder_1.add(paramsSpot_1, 'distance', 50, 200)
    .onChange((val) => { spotLight_1.distance = val });

  SpotLightFolder_1.add(paramsSpot_1, 'angle', 0, Math.PI / 2)
    .onChange((val) => { spotLight_1.angle = val });

  SpotLightFolder_1.add(paramsSpot_1, 'penumbra', 0, 1)
    .onChange((val) => { spotLight_1.penumbra = val });

  SpotLightFolder_1.add(paramsSpot_1, 'decay', 1, 2)
    .onChange((val) => { spotLight_1.decay = val });

  SpotLightFolder_1.add(paramsSpot_1, 'focus', 0, 1)
    .onChange((val) => { spotLight_1.shadow.focus = val });

  SpotLightFolder_1.add(paramsSpot_1, 'spotlight_pos_X', MIN, MAX)
    .onChange((val) => { spotLight_1.position.x = val });

  SpotLightFolder_1.add(paramsSpot_1, 'spotlight_pos_Y', MIN, MAX)
    .onChange((val) => { spotLight_1.position.y = val });

  SpotLightFolder_1.add(paramsSpot_1, 'spotlight_pos_Z', MIN, MAX)
    .onChange((val) => { spotLight_1.position.z = val });

  SpotLightFolder_1.open();
  
  /////////////////////////////////////////////
  // SpotLight 2
  /////////////////////////////////////////////
  spotLight_2.color.set(0xffffff);
  spotLight_2.intensity = 500; // 強さ
  spotLight_2.distance = 100;
  spotLight_2.angle = Math.PI / 2; // 照射角
  spotLight_2.penumbra = 1; // ボケ
  spotLight_2.decay = 1.5; // 減衰率
  spotLight_2.position.set(40, 30, -50);

  spotLight_2.castShadow = true;
  spotLight_2.shadow.mapSize.width = 1024;
  spotLight_2.shadow.mapSize.height = 1024;
  scene.add(spotLight_2);

  // SpotLightHelper
  lightHelper_2 = new THREE.SpotLightHelper(spotLight_2);
  scene.add(lightHelper_2);

  const paramsSpot_2 = {
    color: spotLight_2.color.getHex(),
    intensity: spotLight_2.intensity,
    distance: spotLight_2.distance,
    angle: spotLight_2.angle,
    penumbra: spotLight_2.penumbra,
    decay: spotLight_2.decay,
    focus: spotLight_2.shadow.focus,
    shadows: true,
    spotlight_pos_X: spotLight_2.position.x,
    spotlight_pos_Y: spotLight_2.position.y,
    spotlight_pos_Z: spotLight_2.position.z,
  };

  const SpotLightFolder_2 = gui.addFolder("Spot Light 2");
  SpotLightFolder_2.addColor(paramsSpot_2, 'color')
    .onChange((val) => { spotLight_2.color.setHex(val) });

  SpotLightFolder_2.add(paramsSpot_2, 'intensity', 0, 500)
    .onChange((val) => { spotLight_2.intensity = val });

  SpotLightFolder_2.add(paramsSpot_2, 'distance', 50, 200)
    .onChange((val) => { spotLight_2.distance = val });

  SpotLightFolder_2.add(paramsSpot_2, 'angle', 0, Math.PI / 2)
    .onChange((val) => { spotLight_2.angle = val });

  SpotLightFolder_2.add(paramsSpot_2, 'penumbra', 0, 1)
    .onChange((val) => { spotLight_2.penumbra = val });

  SpotLightFolder_2.add(paramsSpot_2, 'decay', 1, 2)
    .onChange((val) => { spotLight_2.decay = val });

  SpotLightFolder_2.add(paramsSpot_2, 'focus', 0, 1)
    .onChange((val) => { spotLight_2.shadow.focus = val });

  SpotLightFolder_2.add(paramsSpot_2, 'spotlight_pos_X', MIN, MAX)
    .onChange((val) => { spotLight_2.position.x = val });

  SpotLightFolder_2.add(paramsSpot_2, 'spotlight_pos_Y', MIN, MAX)
    .onChange((val) => { spotLight_2.position.y = val });

  SpotLightFolder_2.add(paramsSpot_2, 'spotlight_pos_Z', MIN, MAX)
    .onChange((val) => { spotLight_2.position.z = val });

  SpotLightFolder_2.open();

}


type lightConfType = {
  ambientLight: {
    ambientLight_color: number,
    ambientLight_intensity: number,
  },
  hemisphereLight: {
    hemisphereLight_color: number,
    hemisphereLight_ground_color: number,
    hemisphereLight_intensity: number,
  },
  spotLight_1: {
    spotLight_1_color: number,
    spotLight_1_intensity: number,
    spotLight_1_distance: number,
    spotLight_1_angle: number,
    spotLight_1_penumbra: number,
    spotLight_1_decay: number,
    spotLight_1_shadow_focus: number,
    spotLight_1_position_x: number,
    spotLight_1_position_y: number,
    spotLight_1_position_z: number,
  },
  spotLight_2: {
    spotLight_2_color: number,
    spotLight_2_intensity: number,
    spotLight_2_distance: number,
    spotLight_2_angle: number,
    spotLight_2_penumbra: number,
    spotLight_2_decay: number,
    spotLight_2_shadow_focus: number,
    spotLight_2_position_x: number,
    spotLight_2_position_y: number,
    spotLight_2_position_z: number,
  }
}