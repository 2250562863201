import * as THREE from "three";
import React, { useState, useEffect, useRef } from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { Emoji, unescapeProcess, escapeProcess, mathRoundProc } from "./Utils";
import { getJoinedMonacardList, getMonacardInfoMany, getAssetsBalancesElectrum, signMessageMpurse } from "./Mpurse";
import { ITEMS_KUKAN } from "./InitItemsKukan";
import { camera, scene } from "./KukanManagedClass";
import { getKukanLightConf } from "./ThreeGUIHelper";
// import { switchScreenshotCamera } from "../components/KukanManagedClass";
import { customMessageWindow } from "./CustomMessege";

import { 
  getItemPropAndSetPosArrow,
  manager,
  setPicturesState,
  hideNotUsedCanvas,
  displayNotUsedCanvas,
  changeCanvasPicture,
  editPictureSizeObj,
  editPicturePositionObjSlider,
  editPicturePositionObjNumber,
  editPictureRotateObjSlider,
  editPictureRotateObjNumber,
  editPictureRotateObjNumberInput,
  displayPosArrow,
} from "./ObjectLoaderKukan";

// enum MenuCategory { loading, initCategory };

let pictureName = ""; // 選択されたPictureNameを格納
let userAddress = "";
// let ogpImageTime = 0;
// let doneLoading = false;

const MenuCreateEditKukan = (props) => {
  
  const [ userRoomInfo, setUserRoomInfo] = useState("");
  // const [ stateSaveCreateogpWindow, setStateSaveCreateogpWindow] = useState(false);
  // const [ stateSaveConfirmWindow, setStateSaveConfirmWindow] = useState(false);
  // const [ stateSaveCompleatedWindow, setStateSaveCompleatedWindow] = useState(false);
  // const [ stateDeleteConfirmWindow, setStateDeleteConfirmWindow ] = useState(false);
  // const [ stateDeleteCompleatedWindow, setStateDeleteCompleatedWindow ] = useState(false);
  const [ stateHowToWindow, setStateHowToWindow ] = useState(false);
  const [ stateSelectItemWindow, setStateSelectItemWindow ] = useState(false);
  // const [ menuCategoryState, setCategoryState ] = useState(MenuCategory.initCategory);
  const [ kukanName, setKukanName ] = useState("");
  const [ description, setDescription ] = useState("");
  const [ ownerName, setOwnerName ] = useState("");
  const [ importData, setImportData ] = useState("");
  
  const [ ogpImageName, setOgpImageName ] = useState("");
  const [ kukanNameState, setKukanNameState ] = useState(ITEMS_KUKAN.type === "create" ? "PLEASE ENTER" : "");
  const [ descriptionState, setDescriptionState ] = useState(ITEMS_KUKAN.type === "create" ? "PLEASE ENTER" : "");
  const [ ownerNameState, setOwnerNameState ] = useState(ITEMS_KUKAN.type === "create" ? "PLEASE ENTER" : "");
  const [ monacardHTML, setmonacardHTML ] = useState([]);
  const [ monacardHTMLBase, setMonacardHTMLBase ] = useState([]);
  // const [ btnDisable, setBtnDisable ] = useState(false);
  const [ MESSAGE_WINDOW, set_MESSAGE_WINDOW ] = useState(<></>);

  manager.onLoad = () => {
    // setCategoryState(MenuCategory.initCategory);
    const container = document.getElementById("canvas");
    // container.style.visibility = "visible";
		container.addEventListener("mouseover", handleMouseMoveSetPic);
    document.getElementById("waiting").style.display = "none";
    // setArrowButtonEvent(ITEMS_KUKAN.worldType);
    // doneLoading = true;

    // bootReq();
  }

  useEffect(() => {
    userAddress = props.addr;
    if(ITEMS_KUKAN.type === "create") {
      initFunc();
    } else if(ITEMS_KUKAN.type === "edit") {
      initFunc();
      
      setCurrentKukanDataFromKukanName();
      setKukanData();
    }
    // setTimeout(() => { if(!doneLoading) location.reload() }, 10000);
  }, []);

  async function initFunc() {
    const assetsBalances = await getAssetsBalancesElectrum(userAddress);
    if(assetsBalances === undefined || assetsBalances.length === 0) {
      set_MESSAGE_WINDOW(customMessageWindow(assetsBalances, 10));
    } else {
      const joinedAssetList = getJoinedMonacardList(assetsBalances);
      const monacardList = await getMonacardInfoMany(joinedAssetList);
      if(monacardList[0] === undefined) {
        set_MESSAGE_WINDOW(customMessageWindow(monacardList[0], 11));
      } else {
        setMonacardInfo(monacardList);
        itemGrabState.current = false;
      }
    }
  }

  function setKukanData() {
    setKukanName(ITEMS_KUKAN.currentKukanData.kukan_name);
    setDescription(ITEMS_KUKAN.currentKukanData.description);
    setOwnerName(ITEMS_KUKAN.currentKukanData.owner_name);
    setOgpImageName(ITEMS_KUKAN.currentKukanData.ogp_image_name);
    ITEMS_KUKAN.selectedKukanType = ITEMS_KUKAN.currentKukanData.kukan_type;
  }
  
  /**
   * find and set a kukan name
   * @param userAddress
   */
  function setCurrentKukanDataFromKukanName() {
    // 現状、アドレス1つにつきスペース1つだが、
    // 2つ以上になった場合にmykukan.tsxの用にkukannameを受け取り選択する
    const currentKukan = props.data.kukan_details.kukan;
    if(currentKukan === undefined || currentKukan === null) {
      ITEMS_KUKAN.currentKukanData = null;
    } else {
      ITEMS_KUKAN.currentKukanData = currentKukan[0];
      // ITEMS_KUKAN.currentKukanData.kukan_name = decodeURIComponent(ITEMS_KUKAN.currentKukanData.kukan_name);
      ITEMS_KUKAN.currentKukanData.kukan_name = unescapeProcess(props.data.kukan_details.kukan[0].kukan_name);
      ITEMS_KUKAN.currentKukanData.description = unescapeProcess(props.data.kukan_details.kukan[0].description);
      ITEMS_KUKAN.currentKukanData.kukan_type = unescapeProcess(props.data.kukan_details.kukan[0].kukan_type);
      ITEMS_KUKAN.currentKukanData.owner_name = unescapeProcess(props.data.kukan_details.kukan[0].owner_name);
      // ITEMS_KUKAN.kukanData.user_address = unescapeProcess(props.data.kukan_details.user_address);
    }
  }

  // /**
  //  * ArrowMenu Switch
  //  * ON === true, OFF === false
  //  */
  //  const [ arrowMenu, setArrowMenu ] = useState("ON");
  //  const [ arrowWindowState, setArrowWindowState ] = useState(true);
  //  async function setArrowMenuSwitch() {
  //    if(arrowWindowState) {
  //      setArrowMenu("OFF");
  //      setArrowWindowState(false);
  //    } else {
  //      setArrowMenu("ON");
  //      setArrowWindowState(true);
  //      setTimeout(() => {
  //       setArrowButtonEvent(ITEMS_KUKAN.worldType);
  //      }, 50);
  //     }
  //  }

  async function setMonacardInfo(monacardList) {

    let assetTotalList = [];
    let assetTotalListBase = [];
    
    for(let i = 0; i < monacardList.length; i++) {
      for(let ii = 0; ii < monacardList[i].length; ii++) {
          
        // リサイズ画像があるかチェック
        const isResizeImage = await fetch(ITEMS_KUKAN.url.URL_IMG_DATA + monacardList[i][ii].cid + "l")
          .then((res) => {
            if(!res.ok) { throw new Error("Network response was not ok") }
            return true;
          })
          .catch((e) => { return false });
        const IMG_ORIGNAL = isResizeImage ? ITEMS_KUKAN.url.URL_IMG_DATA + monacardList[i][ii].cid + "l" : ITEMS_KUKAN.url.URL_IMG_DATA + monacardList[i][ii].cid;
        const IMG_PREVIEW = isResizeImage ? ITEMS_KUKAN.url.URL_IMG_DATA + monacardList[i][ii].cid + "t" : ITEMS_KUKAN.url.URL_IMG_DATA + monacardList[i][ii].cid;

        ITEMS_KUKAN.monacardInfoCurrentlyOwned.push(
          {
            assetName: monacardList[i][ii].asset_longname === null ? monacardList[i][ii].asset_common_name: monacardList[i][ii].asset_longname,
            asset: monacardList[i][ii].asset,
            assetgroup: monacardList[i][ii].assetgroup,
            card_name: unescapeProcess(monacardList[i][ii].card_name),
            owner_name: unescapeProcess(monacardList[i][ii].owner_name),
            // imgur_url: unescapeProcess(monacardList[i][ii].imgur_url),
            imgur_url_original: IMG_ORIGNAL,
            imgur_url_preview: IMG_PREVIEW,
            add_description: unescapeProcess(monacardList[i][ii].add_description),
            tw_id: unescapeProcess(monacardList[i][ii].tw_id),
            tw_name: unescapeProcess(monacardList[i][ii].tw_name),
            tag: unescapeProcess(monacardList[i][ii].tag),
            cid: monacardList[i][ii].cid,
            ver: monacardList[i][ii].ver,
            is_good_status: monacardList[i][ii].asseis_good_statustgroup,
            regist_time: monacardList[i][ii].regist_time,
            update_time: monacardList[i][ii].update_time,
          }
        );
      }
    }
    
    for(let i in ITEMS_KUKAN.monacardInfoCurrentlyOwned) {
      let cardLink;
      let cardLinkTumbnail;
      let cardImage = new Boolean(true);
      
      if(ITEMS_KUKAN.monacardInfoCurrentlyOwned[i].imgur_url_original === undefined) {
        cardImage = false;
      } else {
        // サムネイル用のためサイズ小
        if(ITEMS_KUKAN.monacardInfoCurrentlyOwned[i].imgur_url_preview === "/images/default_canvas.png") {
          cardLink = ITEMS_KUKAN.monacardInfoCurrentlyOwned[i].imgur_url_original;
          cardLinkTumbnail = ITEMS_KUKAN.monacardInfoCurrentlyOwned[i].imgur_url_preview;
        } else {
          // gif_listからgifか判定
          if(ITEMS_KUKAN.gifCardList.includes(ITEMS_KUKAN.monacardInfoCurrentlyOwned[i].cid)) {
            cardLink = ITEMS_KUKAN.monacardInfoCurrentlyOwned[i].imgur_url_original.slice(0, -1);
            cardLinkTumbnail = ITEMS_KUKAN.monacardInfoCurrentlyOwned[i].imgur_url_original.slice(0, -1);
          } else {
            cardLink = ITEMS_KUKAN.monacardInfoCurrentlyOwned[i].imgur_url_original;
            cardLinkTumbnail = ITEMS_KUKAN.monacardInfoCurrentlyOwned[i].imgur_url_preview;
          }
        }
      }

      if(cardImage) {
        assetTotalListBase.push([
          ITEMS_KUKAN.monacardInfoCurrentlyOwned[i].assetName,
          <div className="w-full flex justify-between rounded-lg bg-gradient-to-r from-neutral-200 to-white px-2 py-2 mx-auto" key={i}>
            <div className="font-medium md:font-semibold relative w-1/2 break-all text-sm px-2">
              <div className="absolute left-0 top-0">{ ITEMS_KUKAN.monacardInfoCurrentlyOwned[i].assetName }</div>
            </div>
            <div className="z-40 transform rotate-0 hover:rotate-6 hover:scale-105 w-1/2 flex justify-center items-center">
              <button onClick={ () => selectMonacard(ITEMS_KUKAN.monacardInfoCurrentlyOwned[i].assetName, cardLink) }>
                <img className="rounded-lg shadow-xl" src={ cardLinkTumbnail } />
              </button>
            </div>
          </div>
        ]);
        assetTotalList.push(assetTotalListBase[i][1]);
      }
    }
    setMonacardHTMLBase(assetTotalListBase);
    setmonacardHTML(assetTotalList);
  }

  // selectedSpcaeItemsArrayらへんの処理。ITEMS_KUKANを回避する
  //////////////////////////////////////////////////////////////////////////////////////////////
  /**
   * カード一覧からカードを選択した時の処理
   * @param assetName 
   * @param cardLink 
   */
  function selectMonacard(assetName: string, cardLink: string) {
    if(ITEMS_KUKAN.selectedSpcaeItemsArray.length === 0) {
      ITEMS_KUKAN.selectedSpcaeItemsArray.push([ pictureName, assetName ]);
    } else {
      // pictureNameがすでに登録されてるときの処理
      // assetNameを入れ替える
      let has = false;
      ITEMS_KUKAN.selectedSpcaeItemsArray.find(r => {
        if(r[0] === pictureName) {
          r[1] = assetName;
          has = true;
        }
      });
      // pictureNameが登録されていないときの処理
      if(!has) { ITEMS_KUKAN.selectedSpcaeItemsArray.push([ pictureName, assetName ]) }
    }
    
    // ディスプレイ用にサイズを大きくする
    let cardLinkLarge = "";
    if(cardLink.slice(-3) === "png") {
      cardLinkLarge = cardLink.replace("t.png", "l.png");
    } else if(cardLink.slice(-3) === "jpg") {
      cardLinkLarge = cardLink.replace("t.jpg", "l.jpg");
    } else {
      cardLinkLarge = cardLink;
    }
    changeCanvasPicture(cardLinkLarge, pictureName);
  }

  // function setOgpData() {
  //   let ogp_assets_info = "";
  //   for(let i = 0; i < ITEMS_KUKAN.selectedSpcaeItemsArray.length; i++) {
  //     if(ogp_assets_info === "") ogp_assets_info += i + "=" + ITEMS_KUKAN.selectedSpcaeItemsArray[i][1] + "_m_" + ITEMS_KUKAN.selectedSpcaeItemsArray[i][0];
  //     else ogp_assets_info += "&" + i + "=" + ITEMS_KUKAN.selectedSpcaeItemsArray[i][1] + "_m_" + ITEMS_KUKAN.selectedSpcaeItemsArray[i][0];
  //   }
  //   return ogp_assets_info;
  // }

  // function buildOGPImageName() {
  //   const now = new Date();
  //   const nowTime = now.getTime();
  //   const ogp_name = escapeProcess(kukanName) + "_m_" + userAddress + "_m_" + nowTime + ".png";
  //   ogpImageTime = nowTime;
  //   return ogp_name;
  // }

  function joinAssetName() {
    let joinedAssetName = "";
    for(let i = 0; i < ITEMS_KUKAN.selectedSpcaeItemsArray.length; i++) {
      if(ITEMS_KUKAN.selectedSpcaeItemsArray[i][1] === "none") continue;
      joinedAssetName === "" ?
      joinedAssetName = ITEMS_KUKAN.selectedSpcaeItemsArray[i][1] :
      joinedAssetName = joinedAssetName.concat(",", ITEMS_KUKAN.selectedSpcaeItemsArray[i][1]);
    }
    return joinedAssetName;
  }

  async function buildKukanData(isUpdate: boolean) {
    const selectedPictureData: pictureDataType = [null];
    if(isUpdate) {
      // UPDATE
      selectedPictureData.splice(0); // 初期化
      // 入力データの格納
      for(let i = 0; i < ITEMS_KUKAN.selectedSpcaeItemsArray.length; i++) {
        const data = {
          asset_name: ITEMS_KUKAN.selectedSpcaeItemsArray[i][1],
          picture_name: ITEMS_KUKAN.selectedSpcaeItemsArray[i][0],
          picture_state: setPicturesState(ITEMS_KUKAN.selectedSpcaeItemsArray[i][0]),
        }
        selectedPictureData.push(data);
      }
      const kukanDetails = {
        update_at: new Date().getTime(),
        user_address: userAddress,
        kukan: [{
          // kukan_type: ITEMS_KUKAN.selectedKukanType,
          kukan_type: "",
          picture_data: selectedPictureData,
          kukan_name: escapeProcess(kukanName),
          description: escapeProcess(description),
          owner_name: escapeProcess(ownerName),
          sns_id: escapeProcess("@none"),
          req_monacard_names: joinAssetName(),
          light_conf: getKukanLightConf()
          // ogp_assets_info: setOgpData(),
          // ogp_image_name: buildOGPImageName(),
          // ogp_image_name_old: ogpImageName
        }],
      }
      // const ogpImageData = await createOgpImage(selectedPictureData);
      const kukanData = {
        kukan_details: kukanDetails,
        // ogp_image_data: ogpImageData
      }
      return kukanData;
    } else if(!isUpdate) {
      // DELETE
      const kukanDetails = {
        user_address: userAddress,
        kukan: [{
          kukan_name: escapeProcess(kukanName),
          ogp_image_name_old: ogpImageName
        }],
      }
      const kukanData = {
        kukan_details: kukanDetails,
      }
      return kukanData;
    }
  }

  // /**
  //  * Create OGP Image
  //  * @returns 
  //  */
  // async function createOgpImage(selected_picture_data) {

  //   setPleaseWait("crate kukan image...");
  //   await hideNotUsedCanvas(selected_picture_data);

  //   ITEMS_KUKAN.littleMona.object.visible = false;
  //   switchScreenshotCamera("screenshot");

  //   await new Promise(resolve => setTimeout(resolve, 500));

  //   // screen shot
  //   const canvas = document.createElement("canvas");
  //   canvas.width = 600;
  //   canvas.height = 315;
  //   const ctx = canvas.getContext("2d");
  //   ctx.drawImage(ITEMS_KUKAN.renderer.domElement, 0, 0, 600, 315);
  //   const imageData = canvas.toDataURL("image/png")

  //   await new Promise(resolve => setTimeout(resolve, 1000));
    
  //   ITEMS_KUKAN.littleMona.object.visible = true;
  //   switchScreenshotCamera("default");

  //   await displayNotUsedCanvas();
  //   return imageData;
  // }
   
  ////////////////////////////////////////////////////////////////////////////////
  // CHECK EVENT
  ////////////////////////////////////////////////////////////////////////////////
  function checkKukanName(event) {
    event.target.value === "" ? setKukanNameState("PLEASE ENTER") : setKukanNameState("");
    setKukanName(event.target.value);
  }
  function checkDescription(event) {
    event.target.value === "" ? setDescriptionState("PLEASE ENTER") : setDescriptionState("");
    setDescription(event.target.value);
  }
  function checkOwnerName(event) {
    event.target.value === "" ? setOwnerNameState("PLEASE ENTER") : setOwnerNameState("");
    setOwnerName(event.target.value);
  }

  function checkInputInfo() {
    if(kukanName === "") return;
    if(description === "") return;
    if(ownerName === "") return;
    saveKukanData();
    // setStateSaveConfirmWindow(true);
  }

  function checkImportData(event) {
    // event.target.value === "" ? setOwnerNameState("PLEASE ENTER") : setOwnerNameState("");
    setImportData(event.target.value);
  }

  ////////////////////////////////////////////////////////////////////////////////
  // GO TO MY KUKAN
  ////////////////////////////////////////////////////////////////////////////////
  const[ userURL, setuserURL ] = useState("");
  const GoToMyKukanButton = ()=> {
    if(saveResult != Emoji.checkEmoji) return (<></>);
    const tweetURLForCreateEdit = "https://twitter.com/intent/tweet?text=" + escapeProcess(kukanName) + encodeURIComponent(" / " + escapeProcess(ownerName) + " \n#monanosu_kukan\n" + userURL);
    return (
      <>
        <div className={ divMenuPanel01 }>
          <p className="w-full mb-1">COPY & SHARE {Emoji.flashEmoji}</p>
          <div className="flex flex-row mb-8">
            <input id="my_url" readOnly={ true } className="select-all w-full mr-2 px-2 rounded-lg border-2 border-solid border-black font-black" type="text" name="my_url" placeholder="My URL" value={ userURL }></input>
            <a
              className="w-10 opacity-90"
              href={ tweetURLForCreateEdit }
            >
              <StaticImage className="-z-10" src="../images/twitter_icon_blue.png" alt="tweet" />
            </a>
          </div>
          <button className="animate-bounce text-white rounded-lg w-full py-8 bg-gradient-to-r from-pink-300/80 via-indigo-400/80 to-yellow-200/80 transition duration-[250ms] ease-out hover:brightness-125 font-black">
            <a href={ userURL }>GO TO MY KUKAN</a>
          </button>
        </div>
      </>
    );
  }

  ////////////////////////////////////////////////////////////////////////////////
  // User Room Data
  ////////////////////////////////////////////////////////////////////////////////
  const UserRoomData = ()=> {
    if(userRoomInfo === "") return (<></>);
    return (
      <>
        <div className={ divMenuPanel01 }>
          <div className="select-all overflow-auto h-60 p-4 border-4 border-black rounded-lg">
            { userRoomInfo }
          </div>
        </div>
      </>
    );
  }
  

  const[ pleaseWait, setPleaseWait ] = useState("");
  const[ invaildAddr, setInvaildAddr ] = useState("");
  ////////////////////////////////////////////////////////////////////////////////
  // SAVE
  ////////////////////////////////////////////////////////////////////////////////
  const[ saveResult, setsaveResult ] = useState("");
  async function saveKukanData() {
    const isUpdate = true;
    // const now = new Date();
    // const nowTime = now.getTime();
    // setInvaildAddr("");
    // setPleaseWait("please wait...");

    // let message = "SIGN AND EDIT KUKAN.【monanosu kukan】 " + now + ":" + nowTime;
    // let signature = undefined;
    // if(ITEMS_KUKAN.env !== "l") {
      
    //   if(userAddress !== await getAddressMP()) {
    //     setInvaildAddr("please confirm your address");
    //     return;
    //   }

    //   signature = await signMessageMpurse(message)
    //     .then((r) => {
    //       setStateSelectItemWindow(false);
    //       setStateSaveConfirmWindow(false);
    //       setStateSaveCreateogpWindow(true);
    //       return r
    //     })
    //     .catch(() => { return });

    // } else {
    //   signature = "l";
    //   setStateSelectItemWindow(false);
    //   setStateSaveConfirmWindow(false);
    //   setStateSaveCreateogpWindow(true);
    // }

    // // 署名キャンセルの場合はリターン
    // if(signature === undefined) {
    //   setStateSaveCreateogpWindow(false);
    //   setPleaseWait("");
    //   return;
    // }
    // const verifyData = {
    //   user_address: userAddress,
    //   message: message,
    //   signature: signature,
    // }

    // setStateSelectItemWindow(false);
    // setStateSaveConfirmWindow(false);
    // setStateSaveCreateogpWindow(true);

    const kukanData = await buildKukanData(isUpdate);
    const jsonData = JSON.stringify(kukanData);
    setUserRoomInfo(jsonData);

    // setStateSaveCreateogpWindow(false);
    // setStateSaveCompleatedWindow(true);
    // setsaveResult(Emoji.checkEmoji);
    
    // setPleaseWait("please wait...");
    // setBtnDisable(true);
    // setsaveResult(Emoji.batsuEmoji);

    // const calledFunc = httpsCallable(functions, "updateKukanData");
    // calledFunc({
    //   verify_data: verifyData,
    //   kukan_data: kukanData,
    // })
    // .then((r: any) => {
    //   // console.log(r.data.result);
    //   if(r.data.result) {
    //     // SUCCESS
    //     const cut = window.location.href.indexOf("edit");
    //     const baseURL = location.href.slice(0, cut);
    //     const toURL = baseURL + "mykukan?kukanname=";
    //     setPleaseWait("");
    //     setStateSaveCreateogpWindow(false);
    //     setStateSaveCompleatedWindow(true);
    //     setsaveResult(Emoji.checkEmoji);
    //     setuserURL(toURL + encodeURIComponent(escapeProcess(kukanName)) + "&addr=" + userAddress + "&time=" + ogpImageTime);
    //   } else {
    //     // FAILED
    //     setPleaseWait("sorry. save failed. please try again.");
    //     setsaveResult(Emoji.batsuEmoji);
    //     setTimeout(() => {
    //       setStateSaveCreateogpWindow(false);
    //       setStateSaveConfirmWindow(true);
    //     }, 2000);
    //   }
    // })
    // .catch((e) => {
    //   setPleaseWait("sorry. save failed. please try again.");
    //   setsaveResult(Emoji.batsuEmoji);
    //   setTimeout(() => {
    //     setStateSaveCreateogpWindow(false);
    //     setStateSaveConfirmWindow(true);
    //   }, 2000);
    // })
    // .finally(() => {
    //   setBtnDisable(false);
    // });
  }

  // const SaveConfirmWindow = () => {
  //   if(!stateSaveConfirmWindow) return <></>;
  //   return(
  //     <div id="menuField" className="z-30 absolute inset-0 w-full h-full sm:opacity-90 opacity-90 bg-gradient-to-r from-white to-neutral-300 rounded-tl-3xl px-4 py-4 font-black overflow-auto flex justify-center items-center">
  //       <div className="">
  //         <p className="text-3xl text-center mb-4">Output Data ?</p>
  //         <p className="text-xl text-center mb-4">{ pleaseWait }</p>
  //         <p className="text-xl text-center mb-4">{ invaildAddr }</p>
  //         <div className="flex">
  //           <button onClick={ saveKukanData } disabled={ btnDisable } className="m-2 bg-white rounded-lg border-2 border-solid border-black w-full p-4 hover:bg-gray-200 font-black">
  //             YES
  //           </button>
  //           <button onClick={ clickCancelButton } className="m-2 bg-black rounded-lg border-2 border-solid border-black w-full p-4 hover:bg-gray-200 font-black text-white">
  //             CANCEL
  //           </button>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }

  // const SaveCreateogpWindow = () => {
  //   if(!stateSaveCreateogpWindow) return <></>;
  //   return(
  //     <div className="z-30 opacity-80 absolute inset-0 w-full h-full bg-gradient-to-r from-pink-100 via-indigo-100 to-yellow-50 rounded-tl-3xl px-4 py-4 font-black overflow-auto flex justify-center items-center">
  //       <div className="animate-bounce">
  //         <p className="text-3xl text-center mb-4 opacity-70">{ pleaseWait }</p>
  //       </div>
  //     </div>
  //   );
  // }

  // const SaveCompleatedWindow = () => {
  //   if(!stateSaveCompleatedWindow) return <></>;
  //   // setTimeout(() => {
  //   //   setStateSaveCompleatedWindow(false);
  //   //   setStateSelectItemWindow(true);
  //   // }, 2000);
  //   return(
  //     <div id="menuField" className="z-30 opacity-90 absolute inset-0 w-1/2 h-1/2 bg-gradient-to-r from-pink-100 via-indigo-100 to-yellow-50 rounded-tl-3xl px-4 py-4 font-black overflow-auto flex justify-center items-center">
  //       <div className="p-8 select-all">
  //         { userRoomInfo }
  //       </div>
  //     </div>
  //   );
  // }
  
  const SaveButton = () => {
    return (
      <div>
        <button onClick={ checkInputInfo } className="mb-4 rounded-lg shadow-md w-full py-8 bg-white hover:bg-opacity-100 hover:scale-105 font-black bg-opacity-80 transition duration-[250ms] ease-out">
          OUTPUT DATA { saveResult }
        </button>
      </div>
    );
  }

  /**
   * 
   * @returns how to window
   */
  const div_B = "mb-8 p-4 rounded-lg border-2 border-solid border-gray-900";
  const HowToWindow = () => {
    if(!stateHowToWindow) return <></>;
    return (
      <div id="menuField" className="z-20 absolute bottom-0 right-0 p-4 sm:w-1/2 md:w-1/3 w-full h-full rounded-tl-3xl font-black bg-gradient-to-r from-white/90 to-neutral-300/80 overflow-auto">
        <div className="p-2">
          <p className="py-8 text-3xl font-medium">HOW TO</p>
          <div className={ div_B }>
            <p className="">{">>"} STEP 1</p>
            <div className="p-2">
              <p>Click on the canvas where you want to display your card.</p>
            </div>
            <div className="p-2">
              <p>カードを飾りたいキャンバスをクリックします。</p>
            </div>
          </div>
          <div className={ div_B }>
            <p className="">{">>"} STEP 2</p>
            <div className="p-2">
              <p>Select a Monacard.</p>
              <div className="text-sm">
                <p className="underline">(Note 1) Currently, APNGs are displayed as still images. GIFs work.</p>
              </div>
            </div>
            <div className="p-2">
              <p>カードを選択しましょう。</p>
              <div className="text-sm">
                <p className="underline">(注1）現状でAPNGは静止画での表示となります。GIFは動きます。</p>
              </div>
            </div>
          </div>
          <div className={ div_B }>
            <p className="">{">>"} STEP 3</p>
            <div className="p-2">
              <p>Fill in the kukan name, description, etc.</p>
            </div>
            <div className="p-2">
              <p>スペースの名前や説明等を記入しましょう。</p>
            </div>
          </div>
          <div className={ div_B }>
            <p className="">{">>"} STEP 4</p>
            <div className="px-2">
              <p>Click on the "OUTPUT DATA" button.</p>
            </div>
            <div className="p-2">
              <p>OUTPUT DATA ボタンを押しましょう。</p>
            </div>
          </div>
          <div className={ div_B }>
            <p className="">{">>"} STEP 5</p>
            <div className="px-2">
              <p>Paste the output data into your own file !!</p>
            </div>
            <div className="p-2">
              <p>出力されたデータを自分のファイルに貼り付けよう！</p>
            </div>
          </div>
          {/* <div className="p-2">
            <p className="mb-2 text-3xl font-medium">SETTING</p>
            <div className="flex flex-row">
              <span className="w-full py-2">{ Emoji.starEmoji } KEY ICON</span>
              <button onClick={ setArrowMenuSwitch } className="bg-white mb-2 py-2 rounded-lg border-2 border-solid border-black w-1/3 sm:w-1/2 hover:bg-gray-200 font-black">
                { arrowMenu }
              </button>
            </div>
          </div> */}
        </div>
        <Link to="/kukan">
          <button className="bg-white rounded-lg border-2 border-solid border-black w-full py-8 hover:bg-stone-200 font-black mb-2">
            KUKAN HOME
          </button>
        </Link>
        <button onClick={ () => setStateHowToWindow(!stateHowToWindow) } className="bg-black text-white rounded-lg border-2 border-solid border-black w-full py-8 hover:bg-gray-200 font-black">CLOSE</button>
      </div>
    );
  }

  function searchAssetFromWord(event) {
    monacardHTML.splice(0);
    const newMonacardHTML = [];
    for(let i = 0; i < monacardHTMLBase.length; i++) {
      const targetName = monacardHTMLBase[i][0];
      const targetHTML = monacardHTMLBase[i][1];

      if(targetName.match(event.target.value)) {
        newMonacardHTML.push(targetHTML);
      }
      setmonacardHTML(newMonacardHTML);
    }
  }

  const PlusMinus = ({ children, prop }) => {
    let minusName: string;
    let plusName: string;
    let emoji_R = Emoji.plusEmoji;
    let emoji_L = Emoji.minusEmoji
    let clickFunc: React.MouseEventHandler<HTMLButtonElement>;

    switch (prop) {
      case "size": {
        minusName = "size_minus";
        plusName = "size_plus";
        clickFunc = editPictureSize;
        break;
      }
      case "pos_x": {
        minusName = "pos_x_minus";
        plusName = "pos_x_plus";
        clickFunc = editPicturePositionNumber;
        break;
      }
      case "pos_y": {
        minusName = "pos_y_minus";
        plusName = "pos_y_plus";
        clickFunc = editPicturePositionNumber;
        break;
      }
      case "pos_z": {
        minusName = "pos_z_minus";
        plusName = "pos_z_plus";
        clickFunc = editPicturePositionNumber;
        break;
      }
      case "rot_x": {
        minusName = "rot_x_minus";
        plusName = "rot_x_plus";
        emoji_R = Emoji.rotRightEmoji;
        emoji_L = Emoji.rotLeftEmoji;
        clickFunc = editPictureRotateNumber;
        break;
      }
      case "rot_y": {
        minusName = "rot_y_minus";
        plusName = "rot_y_plus";
        emoji_R = Emoji.rotRightEmoji;
        emoji_L = Emoji.rotLeftEmoji;
        clickFunc = editPictureRotateNumber;
        break;
      }
      case "rot_z": {
        minusName = "rot_z_minus";
        plusName = "rot_z_plus";
        emoji_R = Emoji.rotRightEmoji;
        emoji_L = Emoji.rotLeftEmoji;
        clickFunc = editPictureRotateNumber;
        break;
      }
    }
    
    return (
      <div className="w-full flex">
        <button 
          className="text-2xl font-light mt-2 mr-1"
          style={{ fontFamily:"Material Icons" }}
          name={ minusName }
          onClick={ clickFunc }
        > { emoji_L } </button>
        { children }
        <button
          className="text-2xl font-light mt-2 ml-1"
          style={{ fontFamily:"Material Icons" }}
          name={ plusName }
          onClick={ clickFunc }
        > { emoji_R } </button>
      </div>
    );
  }

  const itemGrabState = useRef(null);
  
  let posX_Slider: HTMLInputElement;
  let posY_Slider: HTMLInputElement;
  let posZ_Slider: HTMLInputElement;
  let posX_Number: HTMLInputElement;
  let posY_Number: HTMLInputElement;
  let posZ_Number: HTMLInputElement;
  let rotX_Slider: HTMLInputElement;
  let rotY_Slider: HTMLInputElement;
  let rotZ_Slider: HTMLInputElement;
  let rotX_Number: HTMLInputElement;
  let rotY_Number: HTMLInputElement;
  let rotZ_Number: HTMLInputElement;
  let size_Number: HTMLInputElement;
  let size_Range: HTMLInputElement;

  const input_A = "select-none w-full mb-2 px-2 py-1 rounded-lg border-2 border-gray-800";
  const input_B = "ml-2 w-20 px-2 py-1 rounded-lg border-2 border-gray-800";
  const div_A = "w-full p-4 mb-4 rounded-lg border-2 border-gray-800 border-dotted";
  const divMenuPanel01 = "mb-4";
  
  function selectItemWindowStateWindow() {

    if(!stateSelectItemWindow) return <><div id="menuField"></div></>;
    
    setTimeout(() => {
      posX_Slider = document.getElementById("picure_pos_X_slider") as HTMLInputElement;
      posY_Slider = document.getElementById("picure_pos_Y_slider") as HTMLInputElement;
      posZ_Slider = document.getElementById("picure_pos_Z_slider") as HTMLInputElement;
      rotX_Slider = document.getElementById("picure_rot_X_slider") as HTMLInputElement;
      rotY_Slider = document.getElementById("picure_rot_Y_slider") as HTMLInputElement;
      rotZ_Slider = document.getElementById("picure_rot_Z_slider") as HTMLInputElement;
      
      posX_Slider.addEventListener("mouseup", () => { posX_Slider.value = "0" });
      posY_Slider.addEventListener("mouseup", () => { posY_Slider.value = "0" });
      posZ_Slider.addEventListener("mouseup", () => { posZ_Slider.value = "0" });

      posX_Number = document.getElementById("picure_pos_X_number") as HTMLInputElement;
      posY_Number = document.getElementById("picure_pos_Y_number") as HTMLInputElement;
      posZ_Number = document.getElementById("picure_pos_Z_number") as HTMLInputElement;
      rotX_Number = document.getElementById("picure_rot_X_number") as HTMLInputElement;
      rotY_Number = document.getElementById("picure_rot_Y_number") as HTMLInputElement;
      rotZ_Number = document.getElementById("picure_rot_Z_number") as HTMLInputElement;
      size_Number = document.getElementById("size_number") as HTMLInputElement;
      size_Range = document.getElementById("size_range") as HTMLInputElement;
    }, 500);
  
    return (
      <div id="menuField" 
        className="
          z-20 absolute top-0 right-0 p-4 w-full h-1/2 font-black bg-gradient-to-r from-white/90 to-neutral-300/80
          m-0 overflow-auto sm:bottom-0 sm:w-1/2 md:w-1/3 sm:h-screen sm:rounded-tl-3xl
        "
      >
        <p className="text-3xl font-medium py-8 underline text-center rounded-lg">EDIT MENU</p>

        <div className={ divMenuPanel01 }>
          <p className="w-full mb-1">ADDRESS</p >
          <input id="address" readOnly={ true } className={ input_A } type="text" name="address" placeholder="ADDRESS" value={ userAddress }></input>
        </div>

        {/* <SelectKukanComponent /> */}

        <div className={ divMenuPanel01 }>
          <p className="w-full mb-1">KUKAN NAME</p>
          <p className="w-full mb-1 text-red-500">{ kukanNameState }</p>
          <input id="kukan_name" onChange={ checkKukanName } className={ input_A } type="text" name="kukan_name" placeholder="ENTER KUKAN NAME" value={ kukanName } required></input>
        </div>
        <div className={ divMenuPanel01 }>
          <p className="w-full mb-1">DESCRIPTION</p>
          <p className="w-full mb-1 text-red-500">{ descriptionState }</p>
          <textarea id="description" onChange={ checkDescription } className={ input_A } name="desc" placeholder="ENTER DESCRIPTION" value={ description } required></textarea>
        </div>
        <div className={ divMenuPanel01 }>
          <p className="w-full mb-1">OWNER NAME</p>
          <p className="w-full mb-1 text-red-500">{ ownerNameState }</p>
          <input id="owner_name" onChange={ checkOwnerName } className={ input_A } type="text" name="owner_name" placeholder="ENTER OWNER NAME" value={ ownerName } required></input>
        </div>
        <div className={ divMenuPanel01 }>
          <p className="w-full mb-1">ITEM PROPERTY</p>
          <div className="p-4 border-2 border-black rounded-lg">
            <div className="mt-2" />
            <div className={ div_A }>
              <label className="text-sm">Size [0.01~5]
                <input
                  className={ input_B }
                  type="number" id="size_number" name="size"
                  min="0.1" max="5" step="0.01"
                  defaultValue={ 0 }
                  onChange={ editPictureSize }
                />
                <PlusMinus prop="size">
                  <input
                    className="w-full mt-2 accent-white"
                    type="range" id="size_range" name="size"
                    min="0.1" max="5" step="0.01"
                    defaultValue={ 0 }
                    onChange={ editPictureSize }
                  />
                </PlusMinus>
              </label>
            </div>
            <div>
              <div className={ div_A }>
                <label className="text-sm">Position X [-200~200]
                  <input
                    className={ input_B }
                    type="number" id="picure_pos_X_number" name="pos_x_number"
                    min="-200" max="200" step="0.1"
                    defaultValue={ 0 }
                    onChange={ editPicturePositionNumber }
                  />
                  <PlusMinus prop="pos_x">
                    <input
                      className="w-full mt-2 accent-red-500"
                      type="range" id="picure_pos_X_slider" name="pos_x_slider"
                      min="-10" max="10" step="0.1"
                      defaultValue={ 0 }
                      onChange={ editPicturePositionSlider }
                    />
                  </PlusMinus>
                </label>
              </div>
              <div className={ div_A }>
                <label className="text-sm">Position Y [-200~200]
                  <input
                    className={ input_B }
                    type="number" id="picure_pos_Y_number" name="pos_y_number"
                    min="-200" max="200" step="0.1"
                    defaultValue={ 0 }
                    onChange={ editPicturePositionNumber }
                  />
                  <PlusMinus prop="pos_y">
                    <input
                      className="w-full mt-2 accent-yellow-300"
                      type="range" id="picure_pos_Y_slider" name="pos_y_slider"
                      min="-10" max="10" step="0.1"
                      defaultValue={ 0 }
                      onChange={ editPicturePositionSlider }
                    />
                  </PlusMinus>
                </label>
              </div>
              <div className={ div_A }>
                <label className="text-sm">Position Z [-200~200]
                  <input
                    className={ input_B }
                    type="number" id="picure_pos_Z_number" name="pos_z_number"
                    min="-200" max="200" step="0.1"
                    defaultValue={ 0 }
                    onChange={ editPicturePositionNumber }
                  />
                  <PlusMinus prop="pos_z">
                    <input
                      className="w-full mt-2 accent-blue-600"
                      type="range" id="picure_pos_Z_slider" name="pos_z_slider"
                      min="-10" max="10" step="0.1"
                      defaultValue={ 0 }
                      onChange={ editPicturePositionSlider }
                    />
                  </PlusMinus>
                </label>
              </div>
            </div>
            <div>
              <div className={ div_A }>
                <label className="text-sm">Rotate X [-180~180]
                  <input
                    className={ input_B }
                    type="number" id="picure_rot_X_number" name="rot_dir_x"
                    min="-180" max="180" step="1"
                    defaultValue={ 0 }
                    onChange={ editPictureRotateNumberInput }
                  />
                  <PlusMinus prop="rot_x">
                    <input
                      className="w-full mt-2 accent-red-500"
                      type="range" id="picure_rot_X_slider" name="rot_dir_x"
                      min="-180" max="180" step="1"
                      defaultValue={ 0 }
                      onChange={ editPictureRotateSlider }
                    />
                  </PlusMinus>
                </label>
              </div>
              <div className={ div_A }>
                <label className="text-sm">Rotate Y [-180~180]
                  <input
                    className={ input_B }
                    type="number" id="picure_rot_Y_number" name="rot_dir_y"
                    min="-180" max="180" step="1"
                    defaultValue={ 0 }
                    onChange={ editPictureRotateNumberInput }
                  />
                  <PlusMinus prop="rot_y">
                    <input
                      className="w-full mt-2 accent-yellow-300"
                      type="range" id="picure_rot_Y_slider" name="rot_dir_y"
                      min="-180" max="180" step="1"
                      defaultValue={ 0 }
                      onChange={ editPictureRotateSlider }
                    />
                  </PlusMinus>
                </label>
              </div>
              <div className={ div_A }>
                <label className="text-sm">Rotate Z [-180~180]
                  <input
                    className={ input_B }
                    type="number" id="picure_rot_Z_number" name="rot_dir_z"
                    min="-180" max="180" step="1"
                    defaultValue={ 0 }
                    onChange={ editPictureRotateNumberInput }
                  />
                  <PlusMinus prop="rot_z">
                    <input
                      className="w-full mt-2 accent-blue-600"
                      type="range" id="picure_rot_Z_slider" name="rot_dir_z"
                      min="-180" max="180" step="1"
                      defaultValue={ 0 }
                      onChange={ editPictureRotateSlider }
                    />
                  </PlusMinus>
                </label>
              </div>
            </div>
          </div>
        </div>

        {/* <SelectFrameComponent /> */}
        
        <div className={ divMenuPanel01 }>
          <input id="search_word" onChange={ searchAssetFromWord } className="w-full p-2 rounded-lg border-2 border-black font-black" type="search" placeholder={ Emoji.searchEmoji + " item search" } />
        </div>
        <div className="mb-4 px-2 py-4 text-lg grid grid-cols-2 gap-2 h-2/3 rounded-lg border-2 border-solid border-black overflow-auto">
          { monacardHTML }
        </div>
        <GoToMyKukanButton />
        <UserRoomData />
        <SaveButton />
        {/* <DeleteButton /> */}
        <button onClick={ () => setStateSelectItemWindow(false) } className="mb-2 bg-black text-white rounded-lg shadow-md w-full py-8 hover:scale-105 font-black bg-opacity-80 transition duration-[250ms] ease-out">CLOSE</button>
      </div>
    );
  }

  function updateKukanFromData() {
    ITEMS_KUKAN.type = "edit";
    ITEMS_KUKAN.importData = importData;
    location.reload();
  }
  
  /**
   * SCALE
   * @param e 
   */
  function editPictureSize(e) {
    if(!itemGrabState.current) return;

    let targetValue = e.target.value;
    const dir = e.target.name;

    if(dir === "size_minus") {
      const currentValue = Number(size_Number.value);
      if(currentValue <= 0.1) targetValue = 0.1;
      else targetValue = currentValue - 0.01;
    } else if(dir === "size_plus") {
      const currentValue = Number(size_Number.value);
      if(currentValue >= 5) targetValue = 5;
      else targetValue = currentValue + 0.01;
    } else if(dir === "size") {
      if(targetValue === "") return;
      if(targetValue < 0.1) targetValue = 0.1;
      else if(targetValue > 5) targetValue = 5;
    }

    size_Number.value = targetValue.toString();
    size_Range.value = targetValue.toString();

    editPictureSizeObj(selectPicName.current, targetValue);
  }

  /**
   * POSITION SLIDER
   * スライダー操作で位置を変更
   * @param e 
   */
  function editPicturePositionSlider(e) {
    if(!itemGrabState.current) return;

    const targetValue = 0.1 * e.target.value;
    const dir = e.target.name;

    if(dir === "pos_x_slider") {
      const currentValue = Number(posX_Number.value) + targetValue;
      if(currentValue > 200 || currentValue < -200) return;
      posX_Number.value = currentValue.toString();
    } else if(dir === "pos_y_slider") {
      const currentValue = Number(posY_Number.value) + targetValue;
      if(currentValue > 200 || currentValue < -200) return;
      posY_Number.value = currentValue.toString();
    } else if(dir === "pos_z_slider") {
      const currentValue = Number(posZ_Number.value) + targetValue;
      if(currentValue > 200 || currentValue < -200) return;
      posZ_Number.value = currentValue.toString();
    }
    editPicturePositionObjSlider(selectPicName.current, targetValue, dir);
  }

  /**
   * POSITION NUMBER
   * 「＋」「－」ボタン、インプット部で位置を変更
   * @param e 
   */
  function editPicturePositionNumber(e) {
    if(!itemGrabState.current) return;

    let targetValue = Number(e.target.value);
    let dir = e.target.name;

    // 「＋」「－」ボタンからの時の処理
    switch (dir) {
      case "pos_x_minus": {
        const currentValue = Number(posX_Number.value);
        if(currentValue <= -200) targetValue = -200;
        else targetValue = currentValue - 0.1;
        dir = "pos_x_number";
        posX_Number.value = mathRoundProc(targetValue, 10).toString();
        posX_Slider.value = "0";
        break;
      }
      case "pos_x_plus": {
        const currentValue = Number(posX_Number.value);
        if(currentValue >= 200) targetValue = 200;
        else targetValue = currentValue + 0.1;
        dir = "pos_x_number";
        posX_Number.value = mathRoundProc(targetValue, 10).toString();
        posX_Slider.value = "0";
        break;
      }
      case "pos_y_minus": {
        const currentValue = Number(posY_Number.value);
        if(currentValue <= -200) targetValue = -200;
        else targetValue = currentValue - 0.1;
        dir = "pos_y_number";
        posY_Number.value = mathRoundProc(targetValue, 10).toString();
        posY_Slider.value = "0";
        break;
      }
      case "pos_y_plus": {
        const currentValue = Number(posY_Number.value);
        if(currentValue >= 200) targetValue = 200;
        else targetValue = currentValue + 0.1;
        dir = "pos_y_number";
        posY_Number.value = mathRoundProc(targetValue, 10).toString();
        posY_Slider.value = "0";
        break;
      }
      case "pos_z_minus": {
        const currentValue = Number(posZ_Number.value);
        if(currentValue <= -200) targetValue = -200;
        else targetValue = currentValue - 0.1;
        dir = "pos_z_number";
        posZ_Number.value = mathRoundProc(targetValue, 10).toString();
        posZ_Slider.value = "0";
        break;
      }
      case "pos_z_plus": {
        const currentValue = Number(posZ_Number.value);
        if(currentValue >= 200) targetValue = 200;
        else targetValue = currentValue + 0.1;
        dir = "pos_z_number";
        posZ_Number.value = mathRoundProc(targetValue, 10).toString();
        posZ_Slider.value = "0";
        break;
      }
    }
    editPicturePositionObjNumber(selectPicName.current, targetValue, dir);
  }

  /**
   * ROTATE SLIDER
   * @param e 
   */
  function editPictureRotateSlider(e) {
    if(!itemGrabState.current) return;

    // let targetValue: number;
    let targetValue = Number(e.target.value);

    // 数値の範囲
    if(targetValue < -180) targetValue = -180;
    else if(targetValue > 180) targetValue = 180;

    const val = targetValue * Math.PI / 180;
    const dir = e.target.name;

    if(dir === "rot_dir_x") rotX_Number.value = targetValue.toString();
    else if(dir === "rot_dir_y") rotY_Number.value = targetValue.toString();
    else if(dir === "rot_dir_z") rotZ_Number.value = targetValue.toString();

    editPictureRotateObjSlider(selectPicName.current, val, dir);
  }
  /**
   * ROTATE NUMBER
   * 「＋」「－」ボタン、インプット部で回転を変更
   * @param e 
   */
  function editPictureRotateNumberInput(e) {
    if(!itemGrabState.current) return;

    let targetValueRadian = Number(e.target.value) * Math.PI / 180;
    let dir = e.target.name;

    editPictureRotateObjNumberInput(selectPicName.current, targetValueRadian, dir);
  }
  /**
   * ROTATE NUMBER
   * 「＋」「－」ボタン、インプット部で回転を変更
   * @param e 
   */
  function editPictureRotateNumber(e) {
    if(!itemGrabState.current) return;

    let targetValueRadian = 1 * Math.PI / 180;
    let dir = e.target.name;

    // 「＋」「－」ボタンからの時の処理
    switch (dir) {
      case "rot_x_minus": {
        const currentValue = Number(rotX_Number.value);
        if(currentValue <= -180) {
          targetValueRadian = 0;
        } else {
          targetValueRadian *= -1;
          rotX_Number.value = (currentValue - 1).toString();
        }
        dir = "rot_dir_x";
        break;
      }
      case "rot_x_plus": {
        const currentValue = Number(rotX_Number.value);
        if(currentValue >= 180) {
          targetValueRadian = 0;
        } else {
          rotX_Number.value = (currentValue + 1).toString();
        }
        dir = "rot_dir_x";
        break;
      }
      case "rot_y_minus": {
        const currentValue = Number(rotY_Number.value);
        if(currentValue <= -180) {
          targetValueRadian = 0;
        } else {
          targetValueRadian *= -1;
          rotY_Number.value = (currentValue - 1).toString();
        }
        dir = "rot_dir_y";
        break;
      }
      case "rot_y_plus": {
        const currentValue = Number(rotY_Number.value);
        if(currentValue >= 180) {
          targetValueRadian = 0;
        } else {
          rotY_Number.value = (currentValue + 1).toString();
        }
        dir = "rot_dir_y";
        break;
      }
      case "rot_z_minus": {
        const currentValue = Number(rotZ_Number.value);
        if(currentValue <= -180) {
          targetValueRadian = 0;
        } else {
          targetValueRadian *= -1;
          rotZ_Number.value = (currentValue - 1).toString();
        }
        dir = "rot_dir_z";
        break;
      }
      case "rot_z_plus": {
        const currentValue = Number(rotZ_Number.value);
        if(currentValue >= 180) {
          targetValueRadian = 0;
        } else {
          rotZ_Number.value = (currentValue + 1).toString();
        }
        dir = "rot_dir_z";
        break;
      }
    }
    editPictureRotateObjNumber(selectPicName.current, targetValueRadian, dir);
  }

  const selectPicName = useRef("");
  const raycaster = new THREE.Raycaster();
  const mouse = new THREE.Vector2();
  // ITEMS_KUKAN.handleMouseMoveSetPic = handleMouseMoveSetPic; // KukanManagedClassのuseEffect内で使用
  
  /**
   * Processing when mousse ckick
   * @param event
   * @returns void
   */
  function handleMouseMoveSetPic(event) {

    const element = event.currentTarget;
    const x = event.clientX - element.offsetLeft;
    const y = event.clientY - element.offsetTop;
    const w = element.offsetWidth;
    const h = element.offsetHeight;
  
    mouse.x = (x / w) * 2 - 1;
    mouse.y = -(y / h) * 2 + 1;
  
    raycaster.setFromCamera(mouse, camera);
    const intersects = raycaster.intersectObjects(scene.children);
    
    if(intersects.length <= 0) return
    
    const objPictureName = intersects[0].object.name;
    updatePictureState(objPictureName);
    displayPosArrow(itemGrabState.current);
  }

  function updatePictureState(objPictureName: string) {
    
    if(objPictureName.slice(0, 7) === "Picture") {
      setStateHowToWindow(false);
      selectPicName.current = objPictureName;

      pictureName = objPictureName; // PictureNameを保持する
      setStateSelectItemWindow(!stateSelectItemWindow);

      const selectItemProp = getItemPropAndSetPosArrow(pictureName);
      setItemPropsToInputValue(selectItemProp);

    } else if(objPictureName.slice(0, 6) === "Canvas") {
      setStateHowToWindow(false);
      const picName = "Picture" + objPictureName.slice(-3);
      selectPicName.current = picName;

      pictureName = picName; // PictureNameを保持する
      setStateSelectItemWindow(!stateSelectItemWindow);

      const selectItemProp = getItemPropAndSetPosArrow(pictureName);
      setItemPropsToInputValue(selectItemProp);

    } else {
      itemGrabState.current = false;
    }
  }

  function setItemPropsToInputValue(selectItemProp) {
    (document.getElementById("size_number") as HTMLInputElement).value = selectItemProp.scale.toString();
    (document.getElementById("size_range") as HTMLInputElement).value = selectItemProp.scale.toString();
    (document.getElementById("picure_pos_X_number") as HTMLInputElement).value = selectItemProp.posX.toString();
    (document.getElementById("picure_pos_Y_number") as HTMLInputElement).value = selectItemProp.posY.toString();
    (document.getElementById("picure_pos_Z_number") as HTMLInputElement).value = selectItemProp.posZ.toString();
    (document.getElementById("picure_rot_X_number") as HTMLInputElement).value = (selectItemProp.rotX / (Math.PI / 180)).toString();
    (document.getElementById("picure_rot_Y_number") as HTMLInputElement).value = (selectItemProp.rotY / (Math.PI / 180)).toString();
    (document.getElementById("picure_rot_Z_number") as HTMLInputElement).value = (selectItemProp.rotZ / (Math.PI / 180)).toString();
    (document.getElementById("picure_rot_X_slider") as HTMLInputElement).value = (selectItemProp.rotX / (Math.PI / 180)).toString();
    (document.getElementById("picure_rot_Y_slider") as HTMLInputElement).value = (selectItemProp.rotY / (Math.PI / 180)).toString();
    (document.getElementById("picure_rot_Z_slider") as HTMLInputElement).value = (selectItemProp.rotZ / (Math.PI / 180)).toString();
    itemGrabState.current = true;
  }

  /**
   * Operation MenuIcon display
   * @returns MenuIcon HTML
   */
  const MenuIcons = () => {
    return (
      <>
        <div className="z-10 absolute bottom-0 right-0 sm:w-[10%] lg:w-[8%] xl:w-[6%] w-1/6 p-2 opacity-70">
          <div onClick={ () => setStateSelectItemWindow(!stateSelectItemWindow) } className="mb-2 hover:cursor-grab">
            <StaticImage src="../images/mona_icon3.png" alt="monastatus" />
          </div>
          <div onClick={ () => setStateHowToWindow(!stateHowToWindow) } className="hover:cursor-grab">
            <StaticImage src="../images/about.png" alt="about" />
          </div>
        </div>
      </>
    );
  }

  return (
    <div id="menu" className="text-gray-900">
      { MESSAGE_WINDOW }
      <div><MenuIcons /></div>
      <div><HowToWindow /></div>
      <div>{ selectItemWindowStateWindow() }</div>
    </div>
  );
};

export default MenuCreateEditKukan;